import {LocationModel} from './location.model';
import {
  AssessmentStatus,
  CourseLearningAssessmentModel,
  CourseModel,
  CourseParticipantLearningAssessment,
  CourseParticipantModel,
  CourseParticipantUnitModel,
  CourseUnitModel,
  UnitStatus
} from './course.model';
import {
  ParticipantModel,
  ParticipantRegistrationResponse,
  ParticipantStatus,
  SessionRegistration
} from './participant.model';
import {AccountModel} from './account.model';
import * as moment from 'moment-timezone';
import {BulkAssessmentResponse} from './responses.model';
import {SummaryModel} from './summary.model';

export enum EventStatus {
  SCHEDULED = 'Scheduled',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  PENDING = 'Pending',
  IN_REVIEW = 'In Review',
}

export class EventContact {

  name: string;
  email: string;
  phone: string;

  constructor() {
    this.name = '';
    this.email = '';
    this.phone = '';
  }
}

export enum NotificationRecipients {
  ALL = 'ALL',
  ADMINS = 'ADMINS',
  ASSESSORS = 'ASSESSORS',
  PARTICIPANTS = 'PARTICIPANTS'
}

export enum NotificationPeriod {
  DAY_BEFORE = '1d',
  WEEK_BEFORE = '1w',
  TWO_WEEKS_BEFORE = '2w'
}

export enum DeliveryMethod {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}
export class EventNotificationSettings {
  postEnrolmentInstructions: string;
  sendCompletionEmail: boolean;
  reminderNotifications: Array<{
    recipient: NotificationRecipients | null,
    period: NotificationPeriod | null,
    deliveryMethod: DeliveryMethod,
    when: null
  }>;

  constructor () {
    this.postEnrolmentInstructions = '';
    this.sendCompletionEmail = false;
    this.reminderNotifications = [
      {
        recipient: NotificationRecipients.PARTICIPANTS,
        period: NotificationPeriod.WEEK_BEFORE,
        deliveryMethod: DeliveryMethod.EMAIL,
        when: null
      },
      {
        recipient: NotificationRecipients.PARTICIPANTS,
        period: NotificationPeriod.DAY_BEFORE,
        deliveryMethod: DeliveryMethod.EMAIL,
        when: null
      }
    ];
  }
}

export class EventAdvancedSettings {
  formDefaults: {
    hideBibNumber: boolean,
    hideSpecialNeeds: boolean,
    hideImageCapture: boolean,
    imageCaptureMandatory: boolean,
    autoMarkCompetent: boolean,
    simpleMarkCompetent: boolean,
    showInTrainingEventPage: boolean;
    preventCourseCompletionOnEventComplete: boolean;
  };

  constructor() {
    this.formDefaults = {
      hideBibNumber: true,
      hideSpecialNeeds: true,
      hideImageCapture: true,
      imageCaptureMandatory: false,
      autoMarkCompetent: false,
      simpleMarkCompetent: false,
      showInTrainingEventPage: true,
      preventCourseCompletionOnEventComplete: false,
    };
  }

}

export enum ResourceType {
  LINK = 'LINK'
}

export enum ResourceAudience {
  ASSESSOR = 'ASSESSOR',
  PARTICIPANT = 'PARTICIPANTS',
  ALL = 'ALL'
}

export class Resource {
  resourceType: ResourceType;
  name: string;
  resourceURL: string;

  url?: string;
  audience?: ResourceAudience;
}

export class EventResources {
  assessor: Resource[] = [];
  participant: Resource[] = [];
}


export enum EventCancellationReason {
  BAD_WEATHER = 'Bad Weather',
  NOT_ENOUGH_PARTICIPANTS = 'Not Enough Participants',
  ASSESSOR_WAS_ILL = 'Assessor Was Ill',
  OTHER = 'Other',
}

interface EventSnapshot {
  event: string;
  courses: CourseModel[],
  participants: ParticipantModel[],
  assessment: SummaryModel;
}

export const DEFAULT_PARTICIPANT_SUMMARY = {
  enrolled: 0,
  registered: 0,
  completed: 0,
  interested: 0,
  waitlisted: 0,
  sessionCompleted: 0,
}

export interface EventParticipantSummary {
  interested: number;
  waitlisted: number;
  registered: number;
  enrolled: number;
  completed: number;
  sessionCompleted: number;
  max?: number;
  internal?: number;
  external?: number;
}

export class TermsAndConditions {
  name: string;
  value: string;
}

export class EventEnrolmentOptions {
  enabled: boolean;
  termsAndConditions: TermsAndConditions[];
  enrolmentFormConfig?: [];
  settings: {
    blockEnrolment: boolean;
    blockSignIn: boolean;
    blockEventCompletion: boolean;
  };
}

export class EventDocument {
  _id?: string;
  name: string;
  isExpectedDocument: boolean;
  isRequired: boolean;
  isHardCopy: boolean;
  documentLocation?: string;
  fileData?: string;
  fileName?: string;
  fileType?: string;
  uploadedBy?: { fullName: string, id: string, userName: string } | string;
  dateUploaded?: Date | string;
  isLoading?: boolean;
  order: number;
  remoteDocumentUrl: string;
  hardCopyLocation: string;
  uploadType: EventDocumentUploadType;
}

export enum EventDocumentUploadType {
  UPLOADED = 'UPLOADED',
  HARD_COPY = 'HARD_COPY',
  REMOTE_DOCUMENT = 'REMOTE_DOCUMENT',
  ASSESSMENT_HISTORY = 'ASSESSMENT_HISTORY'
};


export class EventFeedback {
  url: string;
  params: object | null;
}

export type DisabledFields = {
  reviewers?: boolean;
  courses?: boolean;
}

export type EventNotification = {
  assessors: string[];
  participants: string[];
  message?: string;
}

export class ComplianceDetails {
  requireCompliance: boolean;
  complianceDescription: string | null;
  complianceCheckboxLabel: string | null;

  constructor(data?: any) {
    this.requireCompliance = data?.requireCompliance ?? false;
    this.complianceDescription = data?.complianceDescription ?? null;
    this.complianceCheckboxLabel = data?.complianceCheckboxLabel ?? null;
  }
}

export class EventComplianceAcknowledgement {
  assessorCompliance: ComplianceDetails;
  trainerCompliance: ComplianceDetails;

  constructor(data?: any) {
    this.assessorCompliance = new ComplianceDetails(data?.assessorCompliance);
    this.trainerCompliance = new ComplianceDetails(data?.trainerCompliance);
  }
}

export class EventModel {
  isEventTemplate?: boolean;
  eventTemplateName?: string;
  status: EventStatus;
  _id: string;
  name: string;
  description: string;
  eventCode: string | null;
  maxParticipants: number;
  maxExternalParticipants?: number;
  contact: EventContact;
  sessions: Array<EventSessionModel> = [];
  participants: Array<ParticipantModel> = [];
  assessors: Array<EventAssessorModel> = [];
  reviewStages: EventReviewModel;
  courses: Array<CourseModel> = [];
  accounts: Array<AccountModel> = [];
  dateUpdated: Date;
  notifications:  EventNotificationSettings;
  advanced: EventAdvancedSettings;
  resources: EventResources;
  participantSummary?: EventParticipantSummary;
  cancellationReason?: EventCancellationReason;
  enrolmentOptions: EventEnrolmentOptions;
  documents: EventDocument[];
  eventFeedback?: EventFeedback;
  createdBy: (string | {id: string, fullName: string, userName: string});
  updatedBy: string;
  disabledFields: DisabledFields | null;
  summaryNotes: string;
  enableWaitlist: boolean;
  dateCreated: Date;
  complianceAcknowledgement: EventComplianceAcknowledgement;
  constructor (data?: any) {
    this.isEventTemplate = data ? data.isEventTemplate : false;
    this.eventTemplateName = data ? data.eventTemplateName : null;
    this.status = (data) ? ( data.status || '' ) : '';
    this._id = (data) ? ( data._id || '' ) : '';
    this.name = (data) ? ( data.name || '' ) : '';
    this.description = (data) ? ( data.description || '' ) : '';
    this.eventCode = (data) ? ( data.eventCode ? data.eventCode : null ) : null;
    this.maxParticipants = data ? (data.maxParticipants || null) : null
    this.maxExternalParticipants = (data) ? data.maxExternalParticipants : null;
    this.sessions = (data) ? ( data.sessions || [] ) : [];
    this.participants = (data) ? ( data.participants || [] ) : [];
    this.courses = (data) ? ( data.courses || [] ) : [];
    this.accounts = (data) ? ( data.accounts || [] ) : [];
    this.dateCreated = (data) ? (data.dateCreated || null) : null;
    this.dateUpdated = (data) ? (data.dateUpdated || null) : null;
    this.contact = (data) ? (data.contact || new EventContact()) : new EventContact();
    this.notifications = (data) ? (data.notifications || new EventNotificationSettings() ) : new EventNotificationSettings();
    this.resources = (data) ? (data.resources || new EventResources()) : new EventResources();
    this.advanced = (data) ? ( data.advanced || new EventAdvancedSettings() ) : new EventAdvancedSettings();
    this.cancellationReason = (data) ? (data.cancellationReason || '') : '';
    this.participantSummary = (data) ? data.participantSummary : DEFAULT_PARTICIPANT_SUMMARY;
    this.enrolmentOptions = (data) ? (data.enrolmentOptions || new EventEnrolmentOptions()) : new EventEnrolmentOptions();
    this.reviewStages = (data && data.reviewStages) ? new EventReviewModel(data.reviewStages) : new EventReviewModel();
    this.eventFeedback = (data) ? ( data.eventFeedback || new EventFeedback() ) : new EventFeedback();
    this.createdBy = (data && data.createdBy) ? data.createdBy : null;
    this.updatedBy = (data && data.updatedBy) ? data.updatedBy : null;
    this.documents = (data) ? (data.documents || new EventDocument()) : new EventDocument();
    this.disabledFields = data ? data.disabledFields : null;
    this.summaryNotes = data ? data.summaryNotes : null;
    this.enableWaitlist = (data && data.enableWaitlist) ? data.enableWaitlist : false;
    this.complianceAcknowledgement = (data && data.complianceAcknowledgement) ? data.complianceAcknowledgement : new EventComplianceAcknowledgement();

    this.sessions.sort((a, b) => {
      // Check start exists on session (templates don't require a session dates) and sort by start date.
      const a_start = a.start ? moment(a.start).tz(a.timezone).format() : a.start;
      const b_start = b.start ? moment(b.start).tz(b.timezone).format() : b.start
      if (moment(a_start).isAfter(b_start)) {
        return 1;
      } else if (moment(a_start).isBefore(b_start)) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  get eventAssessors(): EventAssessorModel[] {
    let eventAssessors = [];
    this.sessions.forEach((session: EventSessionModel) => {
      let newAssessors = [];
      session.assessors.forEach((sessionAssessor) => {
        if (eventAssessors.findIndex((assessor) => ( assessor.id === sessionAssessor.id )) < 0) {
          newAssessors.push(sessionAssessor);
        }
      });
      eventAssessors = Array.from(new Set([...newAssessors, ...eventAssessors]));
    });
    return eventAssessors;
  }

  get eventTrainers(): EventTrainerModel[] {
    let eventTrainers = [];
    this.sessions.forEach((session: EventSessionModel) => {
      let newTrainers = [];
      session.trainers.forEach((sessionTrainer) => {
        if (eventTrainers.findIndex((trainer) => ( trainer.id === sessionTrainer.id )) < 0) {
          newTrainers.push(sessionTrainer);
        }
      });
      eventTrainers = Array.from(new Set([...newTrainers, ...eventTrainers]));
    });
    return eventTrainers;
  }

  /**
   * A getter to return the event's reviewers.
   * @returns {EventReviewerModel[]}
   */
  get eventReviewers(): EventReviewerModel[] {
    if (!this.reviewStages || !this.reviewStages.reviewers){
      return [];
    }
    return this.reviewStages.reviewers;
  }

  /**
  * Splits Reviewers back into their Review Stage
  */
 get orderReviewers(): EventReviewerModel[][] {
  if (!this.reviewStages || !this.reviewStages.reviewers){
    return [[]];
  }
  const splitReviewStages = [[]];
  this.reviewStages.reviewers.forEach(reviewer => {
    // If the index doesn't exist, create it and set the value to empty array.
    if (!splitReviewStages[reviewer.reviewerOrder - 1]) {
      splitReviewStages[reviewer.reviewerOrder - 1] = [];
    }

    // Add the reviewer to the review stage.
    splitReviewStages[reviewer.reviewerOrder - 1].push(reviewer);
  });
  return splitReviewStages;
}

  /**
   * Get the current session of the event, using the dates and the status of the participants
   * @returns session object that represents the current or next session for this event
   */
  get currentSession(): EventSessionModel {
    let session = null;

    // the assumption here is that the event sessions are ordered by date and
    // the session times do not overlap
    this.sessions.forEach((_session: EventSessionModel) => {
      const sessionStart = moment(_session.start).tz(_session.timezone);
      const now = moment().tz(_session.timezone);
      const startGracePeriod = moment(sessionStart).subtract(3, 'hours');
      // we only want to select the session that is in the past or in progress
      if(startGracePeriod.isBefore(now)) {
        session = _session;
      }
    });
    return session;
  }

  /**
   * Checks if the event is an accredited course.
   * @returns {boolean}
   */
  public isAccreditedCourse(): boolean {
    // Is an accredited course if the event has reviewers.
    return !!this.eventReviewers.length;
  }

  getCourseDetails(courseId: string) {
    return this.courses.find((c) => c.id === courseId );
  }
  /***
   * Find the details of a participant with the given ID, assuming there is a selected event already
   *
   * @param participantId string - ID of the partcipant to search for
   * @param assessorId string
   */
  getParticipantDescription(participantId: string): ParticipantModel {
    return this.participants.find( (participant) => (participant.id === participantId || participant.externalId === participantId));
  }

  getEventSessionUserDescription(id: string, isTrainer: boolean = false): EventAssessorModel {
    const type = isTrainer ? 'trainers' : 'assessors';
    let person = null;

    this.sessions.forEach((session) => {
      if (!person) {
        person = session[type].find((p) => (p.id === id));
      }
    });
    return person;
  }

  getParticipantDetailsFromLearningAssessmentInstanceId(learningAssessmentInstanceId: string): ParticipantModel {
    let participantId = '';
    this.courses.forEach((_c) => {
      _c.participants.forEach((_p) => {
        _p.units.forEach((_u) => {
          _u.learningAssessments.forEach((_la) => {
            if (_la.id === learningAssessmentInstanceId && participantId === '') {
              participantId = _p.id;
            }
          });
        });
      });
    });
    if (participantId !== '') {
      return this.getParticipantDescription(participantId);
    } else {
      return null;
    }
  }

  getUnitDescription(unitId: string, courseId: string): CourseUnitModel {
    return this.courses.find( (course: CourseModel) => course.id === courseId )
      .units.find( (unit: CourseUnitModel) => unit.id === unitId );
  }

  getLearningAssessmentDetails(learningAssessmentId: string, unitId: string, courseId?: string): CourseLearningAssessmentModel {
    if ( typeof courseId === 'undefined' ) {
      let learningAssessment = null;
      this.courses.forEach((_c) => {
        const unit = _c.units.find((_u) =>  (_u.id === unitId));
        if (unit) {
          unit.learningAssessments.forEach((_la) => {
            if (_la.id === learningAssessmentId && !learningAssessment) {
              learningAssessment = _la;
            }
          });
        }
      });
      return learningAssessment;
    } else {
      return this.courses.find((_c) => (_c.id === courseId))
        .units.find((_u) => (_u.id === unitId))
        .learningAssessments.find((_la) => (_la.id === learningAssessmentId));
    }
  }
  /***
   * find the units for a paticipant for a given course, these units will contain the learning assessment statuses
   *
   * @param participantId
   * @param courseId
   *
   * @return boolean false if there is no selected event
   * @return CourseParticipantModel if there is a participant in the course with matching @participantId
   */
  getCourseParticipant(participantId: string, courseId: string): CourseParticipantModel {
    return this.courses.find( ( course: CourseModel ) => course.id === courseId )
      .participants.find( ( participant: CourseParticipantModel ) => participant.id === participantId);
  }

  /***
   * function to get the unit of a course for a participant with the given id
   * @param participantId
   * @param unitId
   * @param courseId
   */
  getCourseParticipantUnit(participantId: string, unitId: string, courseId: string): CourseParticipantUnitModel {
    const participant = this.getCourseParticipant(participantId, courseId);
    if (participant) {
      return participant.units.find( ( unit: CourseParticipantUnitModel ) => unit.unitId === unitId );
    } else {
      return null;
    }
  }

  getCourseParticipantLearningAssessment(participantId: string, learningAssessmentId: string, unitId: string, courseId: string)
    : CourseParticipantLearningAssessment {
    const participantUnit = this.getCourseParticipantUnit(participantId, unitId, courseId);
    return participantUnit.learningAssessments.find( (learningAssessment: CourseParticipantLearningAssessment) =>
      learningAssessment.learningAssessmentId === learningAssessmentId
    );
  }

  getLearningAssessmentDetailsFromLearningAssessmentInstanceId(learningAssessmentInstanceId: string): CourseLearningAssessmentModel {
    let learningAssessmentId = '';
    let unitId = '';
    let courseId = '';
    this.courses.forEach((_c) => {
      _c.participants.forEach((_p) => {
        _p.units.forEach((_u) => {
          _u.learningAssessments.forEach((_la) => {
            if (_la.id === learningAssessmentInstanceId && learningAssessmentId === '') {
              learningAssessmentId = _la.learningAssessmentId;
              unitId = _u.unitId;
              courseId = _c.id;
            }
          });
        });
      });
    });

    if (learningAssessmentId !== '') {
      return this.getLearningAssessmentDetails(learningAssessmentId, unitId, courseId);
    } else {
      return null;
    }
  }

  /**
   * function to get the participant unit from the unit instance id
   * @param id - unit instance id
   * @param courseId
   */
  getCourseParticipantUnitFromId(id: string, courseId: string): CourseParticipantUnitModel {
    let unit: CourseParticipantUnitModel | null = null;
    const course = this.courses.find((_c: CourseModel) => (courseId === _c.id));
    if (course) {
      course.participants.forEach((_p: CourseParticipantModel) => {
         if (_p.units.find((_u: CourseParticipantUnitModel) => (_u.id === id))) {
           unit = _p.units.find((_u: CourseParticipantUnitModel) => (_u.id === id));
         }
      });
    }
    return unit;
  }

  // unused but will reperpose for offline later on
  updateParticipantLearningAssessmentStatus(participantId: string, learningAssessmentId: string, unitId: string, courseId: string
                                            , status: AssessmentStatus) {
    this.courses.find((course) => course.id === courseId)
      .participants.find((participant) => participant.id === participantId)
      .units.find((unit) => unit.unitId === unitId)
      .learningAssessments.find((learningAssessment) => learningAssessment.learningAssessmentId === learningAssessmentId)
      .status = status;

    // check if all assessments in unit are complete... update accordingly
    const _unit = this.courses.find((course) => course.id === courseId)
      .participants.find((participant) => participant.id === participantId)
      .units.find((unit) => unit.unitId === unitId);

    let new_status = UnitStatus.COMPLETE;
    _unit.learningAssessments.forEach((learningAssessment) => {
      if (learningAssessment.status !== AssessmentStatus.COMPLETE) {
        new_status = UnitStatus.IN_PROGRESS;
      }
    });
    this.courses.find((course) => course.id === courseId)
      .participants.find((participant) => participant.id === participantId)
      .units.find((unit) => unit.unitId === unitId).status = new_status;
  }

  updateParticipantUnit(participantId: string, unit: CourseParticipantUnitModel) {
    this.courses.forEach((course) => {
      const participant = course.participants.find((p: CourseParticipantModel) => p.id === participantId);
      if (participant) {
        const unitUpdate = participant.units.find((u) => u.id === unit.id);
        if (unitUpdate) {
          unitUpdate.status = unit.status;
          unitUpdate.assessedPreviously = unit.assessmentActivitySummary.assessedEvent !== this._id || unit.assessmentActivitySummary.assessedAuto || unit.assessmentActivitySummary.assessedManual;
          unitUpdate.assessmentActivitySummary = unit.assessmentActivitySummary;
          // check if the new learning assessment item is in an array
          unit.learningAssessments.forEach(la => {
            // caluculate the assessed previously value for each learning assessment
            la.assessedPreviously = la.assessmentActivitySummary.assessedEvent !== this._id || la.assessmentActivitySummary.assessedManual || la.assessmentActivitySummary.assessedAuto;
          });
          unitUpdate.learningAssessments = unit.learningAssessments;
        }
      }
    });
  }

  updateParticipantLearningAssessmentInstance(
    participantId: string,
    unitInstanceId: string,
    learningAssessmentInstanceId: string,
    status: AssessmentStatus) {
    let participant = null, unit = null, learningAssessment = null;
    this.courses.forEach((_course) => {
      participant = _course.participants.find((_participant) => (_participant.id === participantId));
      if (participant && !unit) {
        unit = participant.units.find((_unit) => ( _unit.id === unitInstanceId));
        if (unit) {
          learningAssessment = unit.learningAssessments.find(
            (_learningAssessment) => (_learningAssessment.id === learningAssessmentInstanceId)
          );
          if (learningAssessment) {
            learningAssessment.status = status;
            let newUnitStatus = UnitStatus.COMPLETE;
            unit.learningAssessments.forEach((_la) => {
              if (_la.status === AssessmentStatus.IN_PROGRESS) {
                newUnitStatus = UnitStatus.IN_PROGRESS;
              }
            });
            unit.status = newUnitStatus;
          }
        }
      }
    });
    return unit;
  }

  bulkUpdateParticipantLearningAssessmentInstance(data: BulkAssessmentResponse, courseId: string, unitInstanceIds: string[], learningInstanceIds: string[]) {
    // bulk assign done from unit level
    if (unitInstanceIds.length <= 0 && learningInstanceIds.length > 0) {
      const participants = this.getCourseDetails(courseId).participants || [];
      for (const participant of participants) {
        for (const unit of participant.units) {
          for (const assessment of unit.learningAssessments) {
            if (assessment.learningAssessmentId === data.assessment.learningAssessmentId &&
              learningInstanceIds.indexOf(assessment.id) >= 0) {
              unitInstanceIds.push(unit.id);
            }
          }
        }
      }
    }
    // update units
    unitInstanceIds.forEach((_u) => {
      const unit = this.getCourseParticipantUnitFromId(_u, courseId);
      let unitStatus = UnitStatus.COMPLETE;
      if (unit) {
        if (data.assessment.learningAssessmentId) {
          const learningAssessment = unit.learningAssessments.find((_la: CourseParticipantLearningAssessment) => (
            _la.learningAssessmentId === data.assessment.learningAssessmentId));
          if (learningAssessment) {
            learningAssessment.status = data.assessment.status;
            learningAssessment.assessmentActivitySummary.assessedEvent = this._id;
            learningAssessment.assessedPreviously = false;
          }
        } else {
          unit.learningAssessments.forEach((_la) => {
            _la.status = data.assessment.status;
            _la.assessmentActivitySummary.assessedEvent = this._id;
            _la.assessedPreviously = false;
          });
        }

        unit.learningAssessments.forEach((_la) => {
          if (_la.status === AssessmentStatus.IN_PROGRESS) {
            unitStatus = UnitStatus.IN_PROGRESS;
          }
        });
        unit.status = unitStatus;
        unit.assessmentActivitySummary.assessedEvent = this._id;
        unit.assessedPreviously = false;
      }
    });

  }

  updateCourseParticipant(participant: CourseParticipantModel, courseId: string) {
    const course = this.courses.find( (_course) => _course.id === courseId);
    if (typeof course.participants.find((_p) => (_p.id === participant.id)) === 'undefined') {
      course.participants.push(participant);
    }
  }

  addParticipant (newParticipantInfo) {
    if (!this.participants) {
      this.participants = [];
    }
    const existingParticipant = this.participants.find((p) => p.id === newParticipantInfo.participant.id );
    // no participant found... lets add them to the event
    if (typeof existingParticipant === 'undefined') {
      const newParticipant = new ParticipantModel();
      newParticipant.id = newParticipantInfo.participant.id;
      newParticipant.fullName = newParticipantInfo.participant.name;
      newParticipant.status = newParticipantInfo.status;
      if (newParticipant.status === ParticipantStatus.REGISTERED) {
        newParticipant.sessionRegistrations[0] = new SessionRegistration();
        newParticipant.sessionRegistrations[0] = newParticipantInfo.sessionRegistrations[0];
      }
      newParticipant.courses = newParticipantInfo.courses;

      this.participants = [...this.participants, newParticipant];
    } else {
      this.updateParticipant(newParticipantInfo);
    }
  }

  removeParticipant(participantId) {
    // change selected participants status to cancelled
    this.participants.find((_p) => (_p.id === participantId)).status = ParticipantStatus.CANCELLED;
  }

  updateParticipant (registrationResponse: ParticipantRegistrationResponse) {
    const _updatedParticipant = this.participants.find((_p) => (_p.id === registrationResponse.participant.id));
    if (_updatedParticipant) {
      _updatedParticipant.sessionRegistrations = registrationResponse.sessionRegistrations;
      _updatedParticipant.courses = registrationResponse.courses;
      _updatedParticipant.status = registrationResponse.status;
    }

  }

  getNextAvailableBibNumber (): number {
    let bibNumber = 0;
    for (let i = 0; i < this.participants.length; i++ ) {
      const participant = this.participants[i];
      if (participant.sessionRegistrations[0].bibNumber > bibNumber) {
        bibNumber = participant.sessionRegistrations[0].bibNumber;
      }
    }
    bibNumber++;
    return bibNumber;
  }

  getStartDate(session?: EventSessionModel): string {
    if (session) {
      return moment(session.start).tz(session.timezone).format('Do MMM YYYY');
    } else {
      return moment(this.sessions[0].start).tz(this.sessions[0].timezone).format('Do MMM YYYY');
    }
  }
  getEndDate(session?: EventSessionModel): string {
    if (session) {
      return moment(session.end).tz(session.timezone).format('Do MMM YYYY');
    } else {
      return moment(this.sessions[0].end).tz(this.sessions[0].timezone).format('Do MMM YYYY');
    }
  }

  getLocation(session?: EventSessionModel): string {
    let streetAddress = '';
    if (session) {
      if (session.location.address2 && session.location.address2.length > 0) {
        streetAddress = ` ${session.location.address2}, `;
      }
      if (session.location.placeName) {
        return `${session.location.placeName},${streetAddress} ${session.location.suburb} ${session.location.state}`;
      } else {
        return '';
      }
    } else {
      if (this.sessions[0].location.address2 && this.sessions[0].location.address2.length > 0) {
        streetAddress = ` ${this.sessions[0].location.address2}, `;
      }
      return `${this.sessions[0].location.placeName},${streetAddress} ${this.sessions[0].location.suburb} ${this.sessions[0].location.state}`;
    }
  }

  getVideoConferenceLink(session: EventSessionModel): string {
    return session.videoConferenceLink;
  }

  setEventComplete(dateCompleted: Date) {
    this.status = EventStatus.COMPLETED;
    this.dateUpdated = dateCompleted;
  }

  setEventInProgress() {
    this.status = EventStatus.IN_PROGRESS;
  }

  /**
   * Checks if any session in the event is today
   * @returns true if any session in the event is today
   */
  isToday(): boolean {
    return this.sessions.some(session => {
      const { start, timezone } = session;
      const startInTimezone = moment.tz(start, timezone);
      return startInTimezone.isSame(moment.tz(timezone), 'day');
    });
  }

  /**
   * Checks if every session in the event is before today
   * @returns true if every session in the event is before today
   */
  isBeforeToday(): boolean {
    return this.sessions.every(session => {
      const { start, timezone } = session;
      const startInTimezone = moment.tz(start, timezone);
      return startInTimezone.isBefore(moment.tz(timezone), 'day');
    });
  }

  /**
   * Checks if every session in the event is after today
   * @returns true if every session in the event is after today
   */
  isAfterToday(): boolean {
    return this.sessions.every(session => {
      const { start, timezone } = session;
      const startInTimezone = moment.tz(start, timezone);
      return startInTimezone.isAfter(moment.tz(timezone), 'day');
    });
  }

  /**
   * Checks if the event is completed, in review, or cancelled
   * @returns {boolean} true if the event is read only
   */
  isReadOnly(): boolean {
    return this.status === EventStatus.COMPLETED ||
      this.status === EventStatus.IN_REVIEW ||
      this.status === EventStatus.CANCELLED;
  }

  getParticipantCount(): number {
    const excludedStatuses = [ParticipantStatus.CANCELLED, ParticipantStatus.WAITLISTED];
    if (this.participants.length === 0) {
      return 0;
    } else {
      return this.participants.filter((participant) => !excludedStatuses.includes(participant.status)).length;
    }
  }

  getFirstSession() {
    // Get the session that is first
    if (this.sessions.length) {
      return this.sessions[0];
    } else {
      return null;
    }
  }

  getLastSession() {
    // get the session that is last
    if (this.sessions.length) {
      return this.sessions[this.sessions.length - 1];
    } else {
      return null;
    }
  }

  public getEventName(): string {
    if (this.eventCode) {
      return `${this.name} ${this.eventCode}`
    } else {
      return this.name
    }
  }
}

export class EventAssessorModel {
  id: string;
  name: string;
  courses: string[];
  externalSystemId?: string;
  hasAcknowledgedCompliance?: boolean;
}

export class EventTrainerModel extends EventAssessorModel {
  probationary?: boolean;
  hasAcknowledgedCompliance?: boolean;
}

export class EventReviewModel {
    currentReviewer: number;
    reviewers: EventReviewerModel[];
    comments: ReviewComment[];

  constructor(data = null) {
    this.currentReviewer = data && data.currentReviewer ? data.currentReviewer : 1;
    this.reviewers = data && data.reviewers ? data.reviewers : [];
    this.comments = data && data.comments ? data.comments : [];
  }
}

export class EventReviewerModel {
  id: string;
  name: string;
  externalSystemId: string;
  reviewerOrder: number;
}
export class ReviewComment {
  comment: string;
  reviewerName: string;
  dateCreated: Date;
  dateUpdated: Date;
}

export type ReviewerConfig = {
  name: string;
  allStageOneReviewers: string;
  allStageTwoReviewers: string;
  all_stage_1_reviewers: string;
  all_stage_2_reviewers: string;
}

export class EventSessionAssessor extends EventAssessorModel {
 sessions: string[]
}
export class EventSessionModel {
  _id?: string;
  name: string;
  start: Date;
  end: Date;
  timezone: string;
  location: LocationModel;
  noLocation: boolean;
  videoConference: boolean;
  videoConferenceLink: string;
  assessors: EventAssessorModel[];
  trainers?: EventTrainerModel[];

  constructor(data) {
    this._id = (data._id) ? data._id : null;
    this.name = data.name ? data.name : null;
    this.start = (data.start) ? data.start : null;
    this.end = (data.end) ? data.end : null;
    this.timezone = (data.timezone) ? data.timezone : null;
    this.location = (data.location) ? data.location : null;
    this.noLocation = (data.noLocation) ? data.noLocation : false;
    this.videoConference = (data.videoConference) ? data.videoConference : false;
    this.videoConferenceLink = (data.videoConferenceLink) ? data.videoConferenceLink : '';
    this.assessors = (data.assessors) ? data.assessors : [];
    this.trainers = (data.trainers) ? data.trainers : [];
  }
  getSessionFormattedDate(endDate: boolean, format?: string) {
    const date = (endDate) ? this.end : this.start;
    if ( typeof format === 'undefined' ) {
      return moment(date).tz(this.timezone).format();
    } else {
      return moment(date).tz(this.timezone).format(format);
    }

  }
}
