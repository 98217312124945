import {
  AssessmentSubmit,
  CourseLearningAssessmentModel,
  CourseParticipantLearningAssessment,
  CourseParticipantModel,
  CourseParticipantUnitModel,
  UnitStatus
} from '../../../../models/course.model';
import {AssessmentGridEmit} from './unit-grid/unit-grid.component';
import {Observable} from 'rxjs';
import {EventsService} from '../../../../services/events.service';
import {Injectable} from '@angular/core';
import {JwtService} from '../../../../services/jwt.service';

export interface ParticipantRow {
  id: string;
  bibNumber: number;
  fullName: string;
  learningAssessments: Array<CourseParticipantLearningAssessment>;
  externalSystemId?: string;
  disabled?: boolean;
}

export interface AssessmentColumn {
  id: string;
  name: string;
  isLearningAssessment: boolean;
  learningAssessments?: Array<CourseLearningAssessmentModel>;
  markAllComplete: boolean;
  isLoading: false;
  hasSupplementaryInfo?: boolean;
}
@Injectable({providedIn: 'root'})
export class UnitsService {
  selectedCourse;

  constructor(
    private eventsService: EventsService
    , private jwtService: JwtService) {}

  getSelectedCourse() {
    return this.selectedCourse;
  }

  setSelectedCourse(course) {
    this.selectedCourse = course;
  }

  updateAssessmentStatus (event: AssessmentGridEmit): Observable<any> {
    if (event.participantIds.length > 1) {
      // submit a bulk assessment
      return this.eventsService.bulkUpdateStatus(
        event.participantIds,
        event.status,
        event.unitId,
        event.unitInstances,
        event.learningAssessmentId,
        event.learningAssessmentInstances,
        this.selectedCourse.id,
      );
    } else if (!event.learningAssessmentId) {
      // Submit a bulk sub assessment
      const [participantId] = event.participantIds;
      const assessmentUpdate = {
        unitInstances: event.unitInstances,
        learningAssessmentInstances: event.learningAssessmentInstances,
        status: event.status
      };
      return this.eventsService.updateStatus(assessmentUpdate, this.eventsService.selectedEvent._id, participantId);
    } else {
      // handle a single assessment
      const [participantId] = event.participantIds;
      const participantUnit = this.eventsService.selectedEvent.getCourseParticipantUnit(
        participantId,
        event.unitId,
        this.selectedCourse.id,
      );
      let learningAssessmentInstances = [];
      if (event.learningAssessmentInstances.length > 0) {
        learningAssessmentInstances = event.learningAssessmentInstances;
      } else {
        learningAssessmentInstances = [participantUnit.learningAssessments.find((la) => (la.learningAssessmentId === event.learningAssessmentId))]
          .map(i => ( {
            instanceId: i.id,
            assessmentActivitySummary: i.assessmentActivitySummary
          } )
        )
      }
      const assessmentUpdate: AssessmentSubmit = {
        unitInstances: [{
          instanceId: participantUnit.id,
          assessmentActivitySummary: participantUnit.assessmentActivitySummary,
        }],
        learningAssessmentInstances,
        status: event.status
      };

      return this.eventsService.updateStatus(assessmentUpdate, this.eventsService.selectedEvent._id, participantId);
    }
  }

  updateAssessmentBulkStatus(event) {
    return this.eventsService.bulkUpdateStatus(
      event.participantIds,
      event.status,
      event.unitId,
      event.unitInstances,
      event.learningAssessmentId,
      event.learningAssessmentInstances,
      this.selectedCourse.id,
    );
  }

  isAssessmentDisabled() {
    const selectedCourse = this.selectedCourse;
    const userId = this.jwtService.getUserId();
    const currentAssessor = this.eventsService.selectedEvent.sessions[0].assessors.find((assessor) => (userId === assessor.id));
    return !(currentAssessor && currentAssessor.courses.indexOf(selectedCourse.id) >= 0);
  }

  userHasAccess() {
    const selectedCourse = this.selectedCourse;
    const userId = this.jwtService.getUserId();
    const currentAssessor = this.eventsService.selectedEvent.eventAssessors.find((assessor) => (userId === assessor.id));
    return (currentAssessor && currentAssessor.courses.indexOf(selectedCourse.id) >= 0);
  }

  isUnitPreviouslyAssessed(participantId: string, unitId: string, eventId: string): boolean {
    // find the unit for participant
    const courseParticipant: CourseParticipantModel = this.selectedCourse.participants.find(p => (p.id === participantId));
    const participantUnit: CourseParticipantUnitModel = courseParticipant.units.find( u => ( u.unitId === unitId ));

    // detrermain if unit is previously assessed
    let assessedPreviously: boolean;
    if (!!participantUnit.assessedPreviously) {
      assessedPreviously = (participantUnit.assessmentActivitySummary.assessedEvent !== eventId && participantUnit.assessmentActivitySummary.assessedEvent !== '' && participantUnit.assessmentActivitySummary.assessedEvent !== null) ||
                            participantUnit.assessmentActivitySummary.assessedAuto ||
                            participantUnit.assessmentActivitySummary.assessedManual;
    } else {
      assessedPreviously = participantUnit.assessedPreviously;
    }
    // if unit assessment is in progress, we want to check the learningAssessment
    if (participantUnit.status === UnitStatus.IN_PROGRESS && !assessedPreviously) {
      for (let i = 0; i < participantUnit.learningAssessments.length; ++i) {
        if (this.isLearningAssessmentPreviouslyAssessed(participantId, unitId, participantUnit.learningAssessments[i].id, eventId)) {
          assessedPreviously = true;
        }
      }
    }
    return assessedPreviously;
  }

  isLearningAssessmentPreviouslyAssessed(participantId: string, unitId: string, learningAssessmentId: string, eventId: string): boolean {
    const courseParticipant: CourseParticipantModel = this.selectedCourse.participants.find(p => (p.id === participantId));
    const participantUnit: CourseParticipantUnitModel = courseParticipant.units.find( u => ( u.unitId === unitId ));
    const learningAssessment: CourseParticipantLearningAssessment = participantUnit.learningAssessments.find( l => (l.id === learningAssessmentId));
    let assessedPreviously = false;
    if (!!learningAssessment.assessedPreviously) {
      if ((learningAssessment.assessmentActivitySummary.assessedEvent !== eventId && learningAssessment.assessmentActivitySummary.assessedEvent !== '' && learningAssessment.assessmentActivitySummary.assessedEvent !== null) ||
        learningAssessment.assessmentActivitySummary.assessedAuto ||
        learningAssessment.assessmentActivitySummary.assessedManual) {
        assessedPreviously = true;
      }
    } else {
      if (learningAssessment.assessedPreviously) {
        assessedPreviously = true;
      }
    }
    return assessedPreviously;
  }
}
