import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

import {environment} from '../../environments/environment';
import { DynamicFormInput } from '../models/dynamicForm.model';
import {OrganisationConfigModel} from '../models/organisation-config.model';
import { ApiUtilsService } from '../shared/api-utils.service';

export const ORG_KEY = 'organisationConfig';

@Injectable({
  providedIn: 'root'
})

export class OrganisationService {
  public isLoading = false;
  public organisation: OrganisationConfigModel;
  public showOrgSwitchButton: boolean;

  constructor(private http: HttpClient, private translate: TranslateService, @Inject(DOCUMENT) private _document: HTMLDocument, private apiUtilsService: ApiUtilsService) {
    if (this.hasOrgSet()) {
      this.organisation = JSON.parse(localStorage.getItem(ORG_KEY));
      this.setFavicon();
    }
    this.showOrgSwitchButton = false;
  }

  public getOrganisationConfig(orgId: string): Promise<OrganisationConfigModel> {
    const url = this.apiUtilsService.setApiUrl(environment.api.getOrganisation, [{key: '{id}', value: orgId}]);

    return this.http.get<OrganisationConfigModel>(url).toPromise();
  }

  public getCurrentOrganisationConfig(): OrganisationConfigModel {
    const localValue = localStorage.getItem(ORG_KEY);
    return localValue === '' ? null : JSON.parse(localValue) as OrganisationConfigModel;
  }

  public getOrganisations() {
    const requestOptions = {
      orgSelector: true,
    };
    return this.http.get(environment.api.getOrganisationList, {
      params: {
        options: JSON.stringify(requestOptions),
      }
    });
  }

  public getOrganisationAppVersion(orgName) {
    return environment.static.organisationAppConfig.find(org => org.name === orgName) ?
      environment.static.organisationAppConfig.find(org => org.name === orgName).currentVersion :
      environment.static.organisationAppConfig.find(org => org.name === 'etrainu').currentVersion;
  }

  public getOrganisationAppLinks(orgName, platform) {
    const orgConfig = environment.static.organisationAppConfig.find(org => org.name === orgName) ?
      environment.static.organisationAppConfig.find(org => org.name === orgName).appStoreLinks :
      environment.static.organisationAppConfig.find(org => org.name === 'etrainu').appStoreLinks;
    return platform === 'ios' ? orgConfig.ios : orgConfig.android;
  }

  public getOrganisationByDomain(domain): Promise<OrganisationConfigModel> {
    return this.http.get<OrganisationConfigModel>(environment.api.getOrganisationByDomain, {params: {domain}}).toPromise();
  }

  public setOrg(organisation: OrganisationConfigModel) {
    localStorage.setItem(ORG_KEY, JSON.stringify(organisation));
    this.organisation = JSON.parse(localStorage.getItem(ORG_KEY));
    this.setTranslationFile();
    this.setFavicon();
  }

  public clearOrg(): void {
    localStorage.removeItem(ORG_KEY);
  }

  public hasOrgSet(): boolean {
    return localStorage.getItem(ORG_KEY)
      && localStorage.getItem(ORG_KEY).length > 0
      && JSON.parse(localStorage.getItem(ORG_KEY)) !== null;
  }

  public setTranslationFile(): void {

    if (this.organisation !== null && 'undefined' !== typeof this.organisation.languageFile &&
      'undefined' !== typeof this.organisation.languageFile[environment.languageEnvironment])
      {
        const [translationFileName] = this.organisation.languageFile[environment.languageEnvironment]
          .split('/')
          .pop()
          .split('.json');
        this.translate.use(translationFileName);
    } else {
      this.translate.use('etrainu');
    }
  }

  public setFavicon() {
    if (this.organisation) {
      this._document.getElementById('appFavicon').setAttribute('href', this.organisation.favicon || 'favicon.ico');
    }
  }

  public getEventNameFormats(): {format: string, default: boolean}[] {
    if (this.organisation.eventConfig.eventAdminForm.eventNameOptions) {
      return [
        {
          format: this.organisation.eventConfig.eventAdminForm.eventNameOptions.defaultFormat,
          default: true
        },
        ...this.organisation.eventConfig.eventAdminForm.eventNameOptions.formats.map((format) => (
            {
              format,
              default: false
            }
        ))
      ]
    } else {
      return [];
    }
  }

  /**
   * Checks if the organisation has account data enabled
   */
  public orgHasAccountDataEnabled(): boolean {
    if (!this.organisation.eventConfig.participantProfileDisplayAttributes) {
      return false;
    }
    const { explicitPartnershipAccounts, explicitPartnershipAccountAncestors } = this.organisation.eventConfig.participantProfileDisplayAttributes;
    return explicitPartnershipAccounts || explicitPartnershipAccountAncestors;
  }

  /**
   * Returns the organisation's enrolment form config options
   * @returns {DynamicFormInput[]} The organisation's enrolment form config options
   */
  public getOrganisationenrolmentFormConfig(): DynamicFormInput[] {
    return this.organisation.eventConfig.enrolmentFormConfig || [];
  }
}
